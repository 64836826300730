import React from 'react';
import PropTypes from 'prop-types';
import styled from 'theme/libs/styled';
import Link from 'core-web/components/Link';
import countryIcons from 'theme/components/icons/countries';
import colors from 'theme/config/colors';
import { above, media } from 'core-web/util/mediaqueries';

const Wrapper = styled('div')`
    & > * {
        display: block;
        color: inherit;
        width: 100%;
        border: none;
        height: 100%;
    }
`;

const CountryLink = styled(Link)();

const MenuItemsWrapper = styled('div')`
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 64px;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.grey};
    ${media.hover} {
        &:hover {
            background: ${colors.lightGrey};
        }
    }
    ${above.lg} {
        height: 36px;
        border: none;
    }

    & div {
        display: flex;
        align-items: center;
    }

    & .country-icon {
        margin-right: 8px;
    }
`;

const Text = styled('p')`
    font-size: 16px;
    line-height: 14px;
    &.selected {
        font-weight: 700;
        text-decoration: underline;
    }

    ${above.lg} {
        font-size: 14px;
    }
`;

const Country = ({ language, langLink, selected }) => {
    const CountryFlagIcon = countryIcons[language.locale];

    return (
        <Wrapper>
            <CountryLink to={!selected && langLink} target="_self">
                <MenuItemsWrapper>
                    <div>
                        {CountryFlagIcon && (
                            <CountryFlagIcon
                                className="country-icon"
                                height={['24px', null, null, '20px']}
                                width={['24px', null, null, '20px']}
                                left="8px"
                            />
                        )}
                        <Text color={colors.black} className={selected ? 'selected' : null}>
                            {language.name}
                        </Text>
                    </div>
                </MenuItemsWrapper>
            </CountryLink>
        </Wrapper>
    );
};

Country.propTypes = {
    langLink: PropTypes.string,
    language: PropTypes.object,
    selected: PropTypes.bool,
};

Country.defaultProps = {
    langLink: '',
    language: {},
    selected: false,
};

export default Country;
