import React from 'react';
import PropTypes from 'prop-types';

import SVG from './base/SVG';

const BasketIcon = ({ color, ...props }) => (
    <SVG fill="none" viewBox="0 0 27 24" {...props}>
        <path
            fill={color}
            d="M26.1 4.57a.84.84 0 00-.66-.35l-16.3-.68a.86.86 0 00-.61.22.8.8 0 00-.05 1.16c.15.15.36.25.58.26l15.2.63-2.98 9.04H7.98l-2.4-12.7a.8.8 0 00-.17-.36c-.1-.11-.21-.2-.34-.25L1.12.04a.87.87 0 00-.63.04.83.83 0 00-.43.44.8.8 0 000 .6c.08.2.24.36.44.44L4 2.9l2.46 12.92c.04.19.14.36.29.49.15.12.34.2.54.2h.4L6.77 19a.66.66 0 00.08.6.7.7 0 00.56.3h.65a2.43 2.43 0 00-.43 2.64 2.59 2.59 0 004.62 0 2.39 2.39 0 00-.42-2.64h5.51a2.43 2.43 0 00-.43 2.64 2.59 2.59 0 004.62 0 2.39 2.39 0 00-.43-2.64h.8c.18 0 .36-.07.5-.2a.67.67 0 000-.96.71.71 0 00-.5-.2H8.4l.76-2.05h12.73c.18 0 .36-.05.5-.16.15-.1.26-.24.31-.4l3.51-10.65a.8.8 0 00-.1-.71zM9.93 22.64a1.1 1.1 0 01-1.04-.67 1.06 1.06 0 01.25-1.2 1.14 1.14 0 011.22-.23c.21.08.39.22.5.4a1.07 1.07 0 01-.13 1.38c-.21.2-.5.32-.8.32zm9.28 0c-.22 0-.43-.07-.62-.19a1.1 1.1 0 01-.4-.49 1.06 1.06 0 01.24-1.18 1.14 1.14 0 011.22-.24c.2.08.38.22.5.4.13.17.2.38.2.6 0 .29-.12.57-.33.77-.21.2-.5.32-.8.32v.01z"
        />
    </SVG>
);

BasketIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

BasketIcon.defaultProps = {
    color: 'currentColor',
    height: '24px',
    width: '27px',
};

export default BasketIcon;
