import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Event from 'core-web/libs/Events';
import { SearchEvents } from 'core-web/libs/Events/constants';
import { injectModel } from 'core-web/state';
import AlgoliaContainer from 'core-web/containers/AlgoliaContainer';

/**
 *
 * @param {string} query - String to search on
 * @param {object} indices - Object with indexes to search against.
 * @param {func} render - Render function
 * @param {object} [renderProps] - Props passed down to render func.
 *
 * @example
 *
 *   <SearchContainer
 *      query="a query"
 *      indices={{
 *          articles: { pageSize: 2 },
 *          categories: { pageSize: 5 },
 *          suggestions: { pageSize: 6 },
 *          spareParts: { pageSize: 8 },
 *          products: { pageSize: 8 },
 *      }}
 *      render={props => <SearchResult {...props} />}
 *   />
 */

const SearchContainer = ({
    application,
    companySpecificFilters,
    indices,
    query,
    render,
    renderProps,
    ruleContexts,
}) => {
    const trackingTimer = useRef();
    const locale = application.locale?.toLowerCase();

    const localeIndices = {
        articles: `posts_${locale || process.env.REACT_APP_DEFAULT_LOCALE}`,
        categories: `posts_${locale || process.env.REACT_APP_DEFAULT_LOCALE}`,
        products: `products_${locale || process.env.REACT_APP_DEFAULT_LOCALE}`,
        spareParts: `spare_parts_${locale || process.env.REACT_APP_DEFAULT_LOCALE}`,
        suggestions: `suggestions_${locale || process.env.REACT_APP_DEFAULT_LOCALE}`,
    };

    const categoriesFilter = companySpecificFilters?.categoriesFilter || [];

    const searchTracking = (query, products, algoliaIndexType) => {
        Event.trigger(SearchEvents.SUBMITTED, {
            query,
            products,
            algoliaIndexType,
        });
    };

    const responseCallback = response => {
        const result = { query, hitsCount: { all: 0 }, totals: { all: 0 } };
        Object.keys(indices).forEach((indexName, index) => {
            const type = response[index] || {};
            result[indexName] = type.hits || [];
            result['queryIds'] = { ...result['queryIds'], [indexName]: type.queryID };

            result.hitsCount[indexName] = (type.hits || []).length || 0;
            result.hitsCount.all += result.hitsCount[indexName];

            result.totals[indexName] = type.nbHits || 0;
            result.totals.all += result.totals[indexName];
        });

        const hasProducts = result.products.length > 0;

        if (trackingTimer.current) {
            clearTimeout(trackingTimer.current);
        }

        if (query) {
            trackingTimer.current = setTimeout(
                searchTracking.bind(
                    this,
                    query,
                    hasProducts ? result.products : result.spareParts,
                    hasProducts ? localeIndices.products : localeIndices.spareParts
                ),
                1250
            );
        }

        return result;
    };

    const getParameters = () =>
        Object.keys(indices).map(index => {
            let filters = [];
            let restrictSearchableAttributes = [];
            let searchQuery = query;
            switch (index) {
                case 'articles':
                    filters = [['post_type:post', 'post_type:page']];
                    break;
                case 'categories':
                    filters = [['post_type:product_category', 'post_type:manufacturer', ...categoriesFilter]];
                    restrictSearchableAttributes = ['title'];
                    break;
                case 'spareParts':
                    searchQuery = searchQuery.replace(/\s/g, '');
                    break;
                default:
                    break;
            }
            return {
                query: searchQuery,
                indexName: localeIndices[index],
                params: indices[index].pageSize
                    ? {
                          hitsPerPage: indices[index].pageSize,
                          page: 1,
                          filters,
                          restrictSearchableAttributes,
                          ruleContexts,
                      }
                    : {},
            };
        });

    return (
        <AlgoliaContainer
            parameters={getParameters()}
            responseCallback={responseCallback}
            render={props => render({ ...props, renderProps })}
        />
    );
};

const indexPropType = PropTypes.exact({ pageSize: PropTypes.number });

SearchContainer.propTypes = {
    application: PropTypes.object.isRequired,
    companySpecificFilters: PropTypes.object,
    indices: PropTypes.shape({
        articles: indexPropType,
        categories: indexPropType,
        spareParts: indexPropType,
        suggestions: indexPropType,
        products: indexPropType,
    }),
    query: PropTypes.string.isRequired,
    render: PropTypes.func.isRequired,
    renderProps: PropTypes.object,
    ruleContexts: PropTypes.array,
};

SearchContainer.defaultProps = {
    companySpecificFilters: null,
    indices: {},
    renderProps: null,
    ruleContexts: [],
};

export default injectModel('application')(SearchContainer);
