import React from 'react';
import PropTypes from 'prop-types';
import Above from 'core-web/components/Breakpoints/Above';
import Below from 'core-web/components/Breakpoints/Below';
import DesktopNavbar from './DesktopNavbar';
import InformationBanner from './InformationBanner';
import MobileNavbar from './MobileNavbar';

const Navbar = ({ content, showBanner, application }) => {
    const menuLinks = content.menu_links.main_menu;
    const uspBanner = content.usp_banner;
    const informationBanner = content.information_banner;

    return (
        <>
            <Above
                breakpoint="nav"
                render={() => <DesktopNavbar menuLinks={menuLinks} uspBanner={uspBanner} application={application} />}
            />
            <Below breakpoint="nav" render={() => <MobileNavbar menuLinks={menuLinks} application={application} />} />
            {informationBanner && showBanner && <InformationBanner content={informationBanner} />}
        </>
    );
};

Navbar.propTypes = {
    application: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    showBanner: PropTypes.bool,
};

Navbar.defaultProps = {
    showBanner: false,
};

export default Navbar;
