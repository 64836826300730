import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import colors from 'theme/config/colors';
import SVG from 'theme/components/icons/base/SVG';

const LogoIcon = ({ ...rest }) => {
    const { t } = useTranslation();

    return (
        <SVG fill="none" viewBox="0 0 792 184" {...rest}>
            <title>{t('fs_logo')}</title>
            <g transform="translate(-81,-79)">
                <path
                    fill={colors.green}
                    d="M 95,217 V 160 H 81 v -23 h 15 c 0,0 -3,-20 9,-29 15,-13 49,-5 49,-5 v 21 c 0,0 -8,-3 -14,1 -5.9,3.9 -5,12 -5,12 h 17 v 23 h -16 v 57 z"
                />
                <path
                    fill={colors.green}
                    d="m 160,217 v -80 h 36 v 18 h 1 c 0,0 4,-12 11,-16 7,-4 19,-3 19,-3 v 32 c 0,0 -12.2,-3.3 -21,4 -6,5 -7,15 -7,15 v 30 z"
                />
                <g id="g3">
                    <rect x="236" y="137" fill={colors.green} width="40" height="80" />
                    <rect x="236" y="102" fill={colors.green} width="40" height="27" />
                </g>
                <path
                    fill={colors.green}
                    d="m 305,215 v -23 c 0,0 13,6 23,7 11,1.1 16,0 18,-2 2,-2 3,-5 -1,-7 -4,-2 -25,-2 -34,-10 -9,-8 -8,-13 -8,-20 0,-7 4,-14 14,-19 10,-5 27,-5 32,-5 8,0 27,3 27,3 v 21 c 0,0 -8.4,-3.1 -18,-4 -5.9,-0.6 -15,0 -17,1 -3.2,1.6 -3.8,5.3 -1,7 5,3 44,1 43,27 -0.9,23 -28,28 -43,28 -15,0 -35,-4 -35,-4 z"
                />
                <path
                    fill={colors.green}
                    d="M 393,217 V 102 h 38 v 45 c 0,0 6,-11 25,-11 27,0 27,20 27,20 v 61 h -38 v -49 c 0,0 0,-8 -7,-8 -7,0 -7,8 -7,8 v 49 z"
                />
                <path
                    fill={colors.green}
                    d="m 580,146 c -6.7,-4.8 -13.4,-9.6 -36,-10 v 0 c -0.5,0 -1,0 -1.5,0 -0.5,0 -1,0 -1.5,0 v 0 c -22.6,0.4 -29.3,5.2 -36,10 -6.9,4.9 -12,14 -12,31 0,17 6.9,26.6 17,34 8.6,6.2 24.4,7.6 31,7.9 v 0.1 c 0,0 0.5,0 1.5,0 1,0 1.5,0 1.5,0 v -0.1 c 6.6,-0.3 22.4,-1.7 31,-7.9 10.1,-7.4 17,-17 17,-34 0,-17 -5.1,-26.1 -12,-31 z m -29,41 c 0,0 0,9 -8,9 -7,0 -8,-9 -8,-9 v -19 c 0,0 1,-9 8,-9 7,0 8,9 8,9 z"
                />
                <path
                    fill={colors.green}
                    d="m 781.5,79 c -42.8,0 -78.8,29.6 -88.8,69.6 -5.8,-6 -15.1,-12.6 -27.7,-12.6 -22,0 -26,17 -26,17 v -15 h -36 v 112 h 39 v -42 c 0,0 5,12 27,12 14.9,0 22.9,-10.6 26.5,-17.4 12.8,35.2 46.5,60.4 86,60.4 50.5,0 91.5,-41.2 91.5,-92 0,-50.8 -41,-92 -91.5,-92 z M 659,187 c 0,0 -1,9 -9,9 -8,0 -9,-9 -9,-9 v -16 c 0,0 2,-9 9,-9 7,0 9,9 9,9 z m 112.2,9.8 -0.4,-7 h -0.2 c -2.3,4.5 -7.1,8 -13.8,8 -9.8,0 -17.4,-8.3 -17.4,-20.6 -0.1,-13.6 8.4,-21.8 18.3,-21.8 6.2,0 10.4,2.9 12.2,6.2 h 0.2 v -24.2 h 7.3 v 49.2 c 0,3.6 0.1,7.7 0.3,10.5 h -6.5 z m 42.7,-1 -16.8,-19.3 v 19.3 h -7.3 v -58.6 h 7.3 v 35.6 l 15.7,-15.6 h 8.9 l -17.7,16.7 19.9,21.9 z"
                />
                <g>
                    <path
                        fill={colors.green}
                        d="m 759.1,161.1 c -7.7,0 -12.2,6.7 -12.2,15.6 0,8.3 4.1,15.1 12.1,15.1 4.9,0 9.4,-3.3 10.8,-8.9 0.3,-1 0.4,-2 0.4,-3.2 v -7 c 0,-1 -0.1,-2.2 -0.3,-3.2 -1.2,-4.6 -5.2,-8.4 -10.8,-8.4 z"
                    />
                </g>
            </g>
        </SVG>
    );
};

LogoIcon.propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

LogoIcon.defaultProps = {
    height: '34px',
    width: '207px',
};

export default LogoIcon;
