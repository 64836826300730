import React, { useCallback, useRef, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Search } from 'core-web/libs/Algolia-v2';
import scroll from 'core-web/libs/scroll';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getState } from 'core-web/state';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';
import DropDownMenu from './DropDownMenu/DropDownMenu';
import MenuItem from './MenuItem';

const Wrapper = styled('div')`
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;
    width: 100%;
    height: 46px;
    overflow: visible;
`;

const Nav = styled('nav')`
    display: flex;
    background-color: ${colors.bgGrey};
    border-radius: 4px;
    overflow: hidden;
`;

interface Item {
    menu_link: {
        url: string;
        text: string;
        link_id: string;
        link_type: string;
        link_title: string;
        link_seo_index: boolean;
    };
    sub_links: null | Item[];
    tabIndex: number;
    thumbnail: null | string;
}

const Menu = React.memo(({ items, isSearchOpen }: { items: Item[]; isSearchOpen: boolean }) => {
    const [activeIndex, setActiveIndex] = useState<number>(-1);
    const subItems = useRef<{ [key: number]: any[] }>({});

    const search = useCallback(async (ids: string[]): Promise<any[]> => {
        try {
            const locale = getState('application' as any).locale.toLowerCase();
            const response = await Search(`posts_${locale}`, '', {
                facets: ['*'],
                facetFilters: [ids.map(id => `external_id:${id}`)],
                hitsPerPage: ids.length,
                attributesToRetrieve: ['settings', 'external_id', 'hero_content'],
            });

            return response.results[0].hits;
        } catch (error) {
            console.error(error);
            return [];
        }
    }, []);

    const onMouseEnter = useCallback(async (index: number) => {
        if (isSearchOpen) {
            return;
        }
        scroll.preventScroll();
        if (activeIndex === index) {
            return;
        }

        const item = items[index];

        if (!subItems.current[index] && item.sub_links) {
            try {
                const hits = await search(item.sub_links.map(sub => sub.menu_link.link_id));

                if (hits.length) {
                    subItems.current[index] = item.sub_links.reduce((acc: any, cur: any) => {
                        if (!cur?.menu_link?.url || !cur?.menu_link?.text) {
                            return acc;
                        }

                        const hit = hits.find(hit => hit?.external_id === parseInt(cur.menu_link.link_id, 10));
                        cur.thumbnail = hit?.settings?.thumbnail?.url || hit?.hero_content?.data?.image?.url;

                        return [...acc, cur];
                    }, []);
                }
            } catch (error) {
                console.error(error);
            }
        }
        requestAnimationFrame(() => {
            document.body.classList[subItems.current[index] ? 'add' : 'remove']('show-header-bg');
            setActiveIndex(index);
        });
    }, []);

    const onMouseLeave = () =>
        requestAnimationFrame(() => {
            if (!isSearchOpen) {
                scroll.allowScroll();
                document.body.classList.remove('show-header-bg');
            }
            setActiveIndex(-1);
        });

    return (
        <Wrapper onMouseLeave={onMouseLeave}>
            <Nav style={{ borderRadius: !!subItems.current[activeIndex] && '4px 4px 0 0' }}>
                {items.map(({ menu_link: link }, index) => (
                    <MenuItem
                        key={`${link.url}_${link.text}`}
                        text={link.text}
                        url={link.url}
                        onClick={onMouseLeave}
                        onMouseEnter={() => onMouseEnter(index)}
                    />
                ))}
            </Nav>

            <DropDownMenu items={subItems.current[activeIndex]} closeMenu={() => setActiveIndex(-1)} />
        </Wrapper>
    );
});

export default Menu;
