import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Events from 'core-web/libs/Events';
import { ProductEvents } from 'core-web/libs/Events/constants';
import { imgixSrc, stormSrc } from 'core-web/libs/image';
import { checkStormMediaUrl } from 'core-web/util/checkStormMediaUrl';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import { getFlag } from 'core-web/util/getFlag';
import getProductStockStatus from 'core-web/util/getProductStockStatus';
import { above } from 'core-web/util/mediaqueries';
import Image from 'core-web/components/Image';
import LinkComponent from 'core-web/components/Link';
import colors from 'theme/config/colors';
import { PLACEHOLDER, PRODUCT_EXPIRED, PRODUCT_REPLACED_PARAMETRIC } from 'theme/config/constants';
import styled from 'theme/libs/styled';
import RoundedButton from 'theme/components/buttons/RoundedButton';
import AddToBasketButton from 'theme/components/product/AddToBasketButton';
import Price from 'theme/components/product/Price';

const Wrapper = styled('div')`
    width: 100%;
    padding: 12px;
    background-color: ${colors.white};
    height: 100%;

    &.basket {
        border: none;
        border-radius: 0;
    }

    &.checkout {
        border-bottom: 1px solid ${colors.grey};

        ${above.lg} {
            margin-bottom: 16px;
        }
    }

    ${above.lg} {
        border: 1px solid ${colors.grey};
        border-radius: 8px;

        &:nth-of-type(2n - 1) {
            margin-right: 16px;
        }
    }
`;

const Div = styled('div')``;

const Row = styled('div')`
    display: flex;
`;

const Column = styled('div')`
    display: flex;
    flex-direction: column;
`;

const Text = styled('p')`
    color: ${colors.black};
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    overflow: hidden;
`;

const ClampedText = styled('p')`
    max-height: 22px;
    overflow: hidden;
`;

const ImageWrapper = styled('div')`
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    min-width: 67px;
    margin-right: 12px;
`;

const ReplacedByWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const ReplacedByHeading = styled('p')`
    color: ${colors.orange};
    font-weight: 600;
`;

const ReplacedByText = styled('p')`
    font: 300 14px/1.29em 'Akkurat';
    color: ${colors.black};
    overflow: hidden;
`;

const ProductListItem = ({
    algoliaPosition,
    algoliaQueryId,
    product,
    withButton,
    onClick,
    isBasketUpsell,
    isCheckoutUpsell,
    image,
    isVariant,
    name,
    onHand,
    partNo,
    priceExVat,
    recommendedPriceExVat,
    subHeader,
    type,
    vatRate,
    url,
}) => {
    const { t } = useTranslation();

    if (product) {
        image = product.image;
        name = product.parentProductName || product.name;
        partNo = product.objectID;
        url = product.url;
        onHand = product.onHand;
        isVariant = !!product.parentProductId;
        type = product.type;
        subHeader = product.subHeader;

        const { pricing } = product;
        priceExVat = pricing?.pricelists?.web?.exVat || pricing?.pricelists?.web?.ex_vat;
        recommendedPriceExVat = pricing?.recommended?.exVat || pricing?.recommended?.ex_vat;
        vatRate = pricing?.vat;
    }

    const isIE = !!(typeof document !== 'undefined' && document.documentMode);
    const tracking = () => {
        Events.trigger(ProductEvents.CLICK, { product });
    };

    const imageTitle = name && name;
    const formattedSubHeader = subHeader && decodeHTMLEntities(subHeader.replace(/<\/{0,1}[^>]+>/g, ''));
    const stockStatus = getProductStockStatus(onHand);

    const imgPlaceholder = PLACEHOLDER;

    const imageSrc = image
        ? stormSrc(image, { m: 'pad', f: 'jpg', w: 80, h: 80 })
        : imgixSrc(imgPlaceholder, { fm: 'jpg', fit: 'fill', fill: 'solid', w: 80, h: 80 });

    const setImageSrc = isVariant ? checkStormMediaUrl(product?.parentImage) || imageSrc : imageSrc;

    const placeholder = image ? stormSrc(image, { m: 'pad', f: 'jpg', q: 10, w: 80, h: 80 }) : imageSrc;

    const isBuyable = !!(type !== 8 && !isVariant && stockStatus.stock); // if not package, not variant and in stock
    const isOutOfStock = (stockStatus?.stock ?? 0) <= 0;
    const isReplaced = !!getFlag(PRODUCT_REPLACED_PARAMETRIC, product.flags) && isOutOfStock;
    const isExpired = !!getFlag(PRODUCT_EXPIRED, product.flags) && isOutOfStock;

    const showReplaced = isReplaced || isExpired;
    const showNormalPrice = !showReplaced;

    return (
        <Wrapper
            data-insights-object-id={partNo}
            data-insights-position={algoliaPosition}
            data-insights-query-id={algoliaQueryId}
            className={isBasketUpsell ? 'basket' : isCheckoutUpsell ? 'checkout' : ''}
        >
            <Row>
                <LinkComponent
                    to={{ pathname: url, state: { algoliaQueryId } }}
                    style={{ width: '100%' }}
                    onClick={() => {
                        onClick && onClick();
                        tracking();
                    }}
                >
                    <Row>
                        <ImageWrapper display={!isIE && 'flex'}>
                            <Image
                                height="auto"
                                maxHeight="100%"
                                width="auto"
                                maxWidth="100%"
                                objectFit="contain"
                                format="jpg"
                                loading="lazy"
                                placeholder={placeholder}
                                alt={imageTitle && imageTitle}
                                title={imageTitle && imageTitle}
                                src={{ url: setImageSrc, width: [50, 100] }}
                                sizes="25vw"
                                scale="down"
                            />
                        </ImageWrapper>
                        <Div maxWidth="calc(100% - 84px)">
                            <Column pr="8px">
                                <Text>{name}</Text>
                                <ClampedText color="black" fontSize="14px" lineHeight="22px">
                                    {formattedSubHeader}
                                </ClampedText>
                                {showReplaced && (
                                    <ReplacedByWrapper mb={isExpired ? '2px' : null}>
                                        <ReplacedByHeading>
                                            {t(isExpired ? 'stock.expired' : 'stock.replaced')}
                                        </ReplacedByHeading>
                                        {!isExpired && <ReplacedByText>{t('replaced_more_info')}</ReplacedByText>}
                                    </ReplacedByWrapper>
                                )}
                                {showNormalPrice && (
                                    <Price
                                        hideButton
                                        isCheckoutUpsell={isCheckoutUpsell}
                                        priceExVat={priceExVat}
                                        recommendedPriceExVat={recommendedPriceExVat}
                                        vatRate={vatRate}
                                    />
                                )}
                            </Column>
                        </Div>
                    </Row>
                </LinkComponent>
                {withButton && (
                    <Div ml="auto" whiteSpace="nowrap">
                        {isBuyable ? (
                            <AddToBasketButton
                                isBasketUpsell={isBasketUpsell}
                                isCheckoutUpsell={isCheckoutUpsell}
                                objectId={partNo}
                                stockStatus={stockStatus}
                                size="medium"
                                width="92px"
                                padding="0"
                            >
                                {t('add')}
                            </AddToBasketButton>
                        ) : (
                            <RoundedButton
                                isVolatile
                                as={LinkComponent}
                                to={{ pathname: url, state: { algoliaQueryId } }}
                                size="medium"
                                onClick={() => {
                                    onClick && onClick();
                                    tracking();
                                }}
                            >
                                {t('go_to')}
                            </RoundedButton>
                        )}
                    </Div>
                )}
            </Row>
        </Wrapper>
    );
};

ProductListItem.propTypes = {
    algoliaPosition: PropTypes.number,
    algoliaQueryId: PropTypes.string,
    image: PropTypes.string,
    isBasketUpsell: PropTypes.bool,
    isCheckoutUpsell: PropTypes.bool,
    isVariant: PropTypes.bool,
    name: PropTypes.string,
    onClick: PropTypes.func,
    onHand: PropTypes.object,
    partNo: PropTypes.string,
    priceExVat: PropTypes.number,
    product: PropTypes.object,
    recommendedPriceExVat: PropTypes.number,
    subHeader: PropTypes.string,
    type: PropTypes.number,
    url: PropTypes.string,
    vatRate: PropTypes.number,
    withButton: PropTypes.bool,
};

ProductListItem.defaultProps = {
    algoliaPosition: null,
    algoliaQueryId: null,
    image: null,
    isBasketUpsell: false,
    isCheckoutUpsell: false,
    isVariant: false,
    name: null,
    onClick: null,
    onHand: {},
    partNo: null,
    priceExVat: 0,
    product: null,
    recommendedPriceExVat: 0,
    subHeader: null,
    type: null,
    url: null,
    vatRate: 0,
    withButton: true,
};

export default ProductListItem;
