import loadable from '@loadable/component';

const COUNTRIES = {
    'sv_SE': loadable(() => import('./Sweden')),
    'fi_FI': loadable(() => import('./Finland')),
    'da_DK': loadable(() => import('./Denmark')),
    'nb_NO': loadable(() => import('./Norway')),
};

export default COUNTRIES;
