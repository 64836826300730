import React from 'react';
import PropTypes from 'prop-types';

import SVG from './base/SVG';

const viewBoxes = {
    up: '0 0 16 10',
    right: '0 0 10 16',
    down: '0 0 16 10',
    left: '0 0 10 16',
};

const widths = {
    up: '16px',
    right: '10px',
    down: '16px',
    left: '10px',
};

const heights = {
    up: '10px',
    right: '16px',
    down: '10px',
    left: '16px',
};

const paths = {
    up: 'M15 9L8 1 1 9',
    right: 'M1 15l8-7-8-7',
    down: 'M1 1l7 8 7-8',
    left: 'M9 1L1 8l8 7',
};

const ChevronIcon = ({ color, direction, width, height, strokeWidth, ...props }) => {
    width = width || widths[direction];
    height = height || heights[direction];

    return (
        <SVG fill="none" viewBox={viewBoxes[direction]} width={width} height={height} {...props}>
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={strokeWidth}
                d={paths[direction]}
            />
        </SVG>
    );
};

ChevronIcon.propTypes = {
    color: PropTypes.string,
    direction: PropTypes.oneOf(['up', 'right', 'down', 'left']).isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    strokeWidth: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

ChevronIcon.defaultProps = {
    color: 'currentColor',
    height: null,
    strokeWidth: '2',
    width: null,
};

export default ChevronIcon;
