import React from 'react';
import PropTypes from 'prop-types';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import Link from 'core-web/components/Link';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const Div = styled('div')`
    display: flex;
    justify-content: center;
`;

const Paragraph = styled('p')`
    font-size: 14px;
    line-height: 22px;
    text-align: center;
`;

const StyledLink = styled(Link)`
    padding-left: 4px;
    color: inherit;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: ${({ color }) => color};
    &:hover {
        text-decoration: none;
    }
`;

const colorMap = {
    'orange': colors.white,
    'black': colors.white,
    'green': colors.white,
    'grey': colors.black,
};

const InformationBanner = ({ content: { background, enabled, text, link } }) => {
    const linkUrl = link?.url;
    const linkText = link?.text;

    return (
        enabled && (
            <Div bg={colors[background] || colors.grey} justifyContent="center" p="8px">
                <Paragraph color={colorMap[background] || colors.black} dangerouslySetInnerHTML={{ __html: text }} />

                {linkUrl && (
                    <StyledLink to={linkUrl} color={colorMap[background] || colors.black}>
                        <Paragraph color={colorMap[background] || colors.black}>
                            {decodeHTMLEntities(linkText)}
                        </Paragraph>
                    </StyledLink>
                )}
            </Div>
        )
    );
};

InformationBanner.propTypes = {
    content: PropTypes.shape({
        background: PropTypes.string,
        enabled: PropTypes.bool,
        link: PropTypes.object,
        text: PropTypes.string,
    }),
};

InformationBanner.defaultProps = {
    content: {
        background: 'grey',
        enabled: false,
        link: {},
        text: '',
    },
};

export default InformationBanner;
