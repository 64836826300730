import React, { PureComponent } from 'react';
import md5 from 'blueimp-md5';
import PropTypes from 'prop-types';
import { MultiQuery, Search } from 'core-web/libs/Algolia-v2';
import { inServer } from 'core-web/constants';
import { PromisesConsumer, addPromise } from 'core-web/contexts/promises-context';

class AlgoliaContainer extends PureComponent {
    static generateUuid = parameters => {
        const stringToReplace = '_price_desc';

        parameters = parameters.map(parameter => {
            if (parameter.indexName.includes(stringToReplace)) {
                parameter.indexName = parameter.indexName.replace(stringToReplace, '');
            }
            return parameter;
        });

        return md5(`algoliaContainer_${JSON.stringify(parameters)}`);
    };

    firstRender = false;
    uuid = null;

    state = {
        formattedResponse: null,
        promise: null,
    };

    static propTypes = {
        parameters: PropTypes.arrayOf(
            PropTypes.exact({
                indexName: PropTypes.string,
                query: PropTypes.string,
                params: PropTypes.exact({
                    facets: PropTypes.array,

                    // Important to pay attention to the structure
                    filters: PropTypes.arrayOf(
                        // AND
                        PropTypes.arrayOf(
                            // OR
                            PropTypes.string
                        )
                    ),
                    ruleContexts: PropTypes.array,
                    restrictSearchableAttributes: PropTypes.arrayOf(PropTypes.string),
                    // use this together
                    page: PropTypes.number,
                    hitsPerPage: PropTypes.number,
                    attributesToRetrieve: PropTypes.arrayOf(PropTypes.string),
                    // use this together
                    offset: PropTypes.number,
                    length: PropTypes.number,
                }),
            })
        ),
        render: PropTypes.func,
        renderProps: PropTypes.object,
        // this needs to return response data
        responseCallback: PropTypes.func,
    };

    static defaultProps = {
        parameters: [],
        render: () => {},
        renderProps: {},
        responseCallback: i => i,
    };

    constructor(props) {
        super(props);
        this.uuid = AlgoliaContainer.generateUuid(props.parameters);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.parameters) !== JSON.stringify(prevProps.parameters)) {
            this.uuid = AlgoliaContainer.generateUuid(this.props.parameters);
            this.getPromise();
        }
    }

    getPromise = (state = {}) => {
        const { parameters, responseCallback } = this.props;
        const { promises, responses } = state;
        let promise = null;
        if (responses && responses[this.uuid]) {
            return new Promise(resolve => resolve());
        } else if (!promises || !promises[this.uuid]) {
            promise = new Promise((resolve, reject) => {
                let query;
                if (parameters.length === 1) {
                    const param = parameters[0];
                    query = Search(param.indexName, param.query, param.params, param.sort);
                } else {
                    query = MultiQuery(parameters);
                }

                query
                    .then(responses => {
                        if (responses.results || responses.hits) {
                            const results = responses.results || (responses.hits && responses);
                            this.setState({
                                formattedResponse: responseCallback(results),
                            });
                            resolve(results);
                        }
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

            // Add promise to context
            if (inServer) {
                addPromise(this.uuid, promise, '/');
            } else {
                this.setState({ promise });
            }
        } else {
            promise = promises[this.uuid].promise;
        }

        return promise;
    };

    render() {
        const { render, renderProps, responseCallback } = this.props;
        const { promise, formattedResponse } = this.state;

        return (
            <PromisesConsumer>
                {({ state }) => {
                    if (promise) {
                        return render({ promise, response: formattedResponse, renderProps });
                    }

                    if (!this.firstRender && !formattedResponse) {
                        if (state.responses[this.uuid] && state.responses[this.uuid].response) {
                            this.firstRender = true;
                            return render({
                                //promise: this.getPromise(state),
                                response: responseCallback(state.responses[this.uuid].response),
                                renderProps,
                            });
                        }
                    }

                    return render({ promise: this.getPromise(state), renderProps });
                }}
            </PromisesConsumer>
        );
    }
}

export default AlgoliaContainer;
