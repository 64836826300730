import React from 'react';
import PropTypes from 'prop-types';

import SVG from './base/SVG';

const SearchIcon = ({ color, ...props }) => (
    <SVG fill="none" viewBox="0 0 25 25" {...props}>
        <g clipPath="url(#clip0)">
            <path
                fill={color}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.61 4.55A8.54 8.54 0 114.53 16.63 8.54 8.54 0 0116.61 4.55zm2.05 12.8a10.54 10.54 0 10-1.43 1.4l5.9 5.92a1 1 0 001.42-1.42l-5.9-5.9z"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <path fill={color} d="M0 0h25v25H0V0z" />
            </clipPath>
        </defs>
    </SVG>
);

SearchIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

SearchIcon.defaultProps = {
    color: 'currentColor',
    height: '25px',
    width: '25px',
};

export default SearchIcon;
