import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useBelowBreakpoint from 'core-web/hooks/useBelowBreakpoint';
import { getState } from 'core-web/state';
import get from 'core-web/util/get';
import { above, below } from 'core-web/util/mediaqueries.js';
import Link from 'core-web/components/Link';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';
import ProductCard from 'theme/components/product/ProductCard';

// General
const Div = styled('div')();
const SearchWrapper = styled('div')`
    position: absolute;
    top: 58px;
    left: 0;
    right: 0;
    z-index: 4;
    height: auto;
`;

const SearchWrapperInner = styled('div')`
    display: flex;
    min-height: 337px;
    max-height: calc(100vh - 90px);
    max-width: 1408px;
    margin: 0 auto;
    padding: 24px 16px;
    overflow: hidden;

    background-color: ${colors.lightGrey};
    border: 1px solid ${colors.grey};
    border-radius: 4px;
`;

const SearchHeading = styled('div')`
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
`;

// Sidebar
const SearchSidebar = styled('div')`
    padding-right: 16px;
    overflow-y: auto;
    ${below.xl} {
        flex: 1 0 256px;
        max-width: 256px;
    }
    ${above.xl} {
        flex: 1 0 324px;
        max-width: 324px;
    }
`;

const SearchSidebarColumn = styled('div')`
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid ${colors.grey};
`;

const SidebarHeading = styled(SearchHeading)`
    margin-bottom: 16px;
`;

const textStyling = as => styled(as)`
    display: block;
    background: none;
    border-bottom: 0;
    padding: 0;

    color: inherit;
    font-size: 16px;
    font-weight: inherit;
    line-height: 32px;
    text-align: left;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover,
    &:focus {
        opacity: 1;
        text-decoration: underline;
        em {
            text-decoration: none;
        }
    }
    em {
        font-style: normal;
        text-decoration: underline;
    }
`;

const SearchCategoryLink = textStyling(Link);
const SearchCategoryButton = textStyling('button');
SearchCategoryButton.defaultProps = {
    type: 'button',
};

// Products
const Wrapper = styled('div')`
    display: flex;
    width: 100%;
`;

const SpareParts = styled('div')`
    display: flex;
    flex: 0 0 33.52%;
    flex-direction: column;
    margin: 0 8px 0 8px;
    position: relative;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    ${above.xl} {
        flex: 0 0 25%;
    }
`;

const Content = styled('div')`
    display: flex;

    flex-direction: column;
    position: relative;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`;

const ProductWrapper = styled('div')`
    display: flex;
    margin: 0 -8px;
    flex-wrap: wrap;
`;

const ProductCol = styled('div')`
    display: flex;
    background-color: ${colors.white};
    margin: 0 8px 16px;

    ${below.xl} {
        width: calc(${({ sparePartExsists }) => (sparePartExsists ? '50%' : '33%')} - 16px);
        &:nth-of-type(n + 7) {
            display: none;
        }
    }
    ${above.xl} {
        width: calc(${({ sparePartExsists }) => (sparePartExsists ? '33%' : '25%')} - 16px);
    }

    &.spare-parts {
        margin: 0 8px 16px;

        width: calc(99% - 16px);
    }
`;

const SearchContentHeader = styled('div')`
    display: flex;
    padding-bottom: 24px;
    margin-bottom: 16px;

    border-bottom: 1px solid ${colors.grey};
    width: 100%;
    align-items: center;
`;

const ShowMoreButton = styled(Link)`
    width: 338px;
    height: 56px;
    margin-top: 16px;
    align-self: center;
    padding: 20px;
    border-radius: 4px;
    flex: 0 0 auto;
    background-color: ${colors.darkGrey};
    color: white;
    cursor: pointer;
    font: 600 16px/1em 'Akkurat';
    text-transform: uppercase;
    text-align: center;

    &:hover {
        background-color: ${colors.black};
    }
`;

const SearchContent = ({
    articles,
    searchQuery,
    queryIds,
    products,
    suggestions,
    categories,
    spareParts,
    totals,
    closeSearch,
    onSearch,
}) => {
    const [allProducts, setAllProducts] = useState(products);

    useEffect(() => {
        const handlePriceLists = async products => {
            setAllProducts(products);

            const { getProductPrices, pricelistSeed } = getState('customer');
            if (pricelistSeed) {
                const productsFromPricelist = await getProductPrices(products);

                if (productsFromPricelist?.length && Array.isArray(productsFromPricelist)) {
                    setAllProducts(productsFromPricelist);
                }
            }
        };

        handlePriceLists(products);
    }, [products]);

    const { t } = useTranslation();
    const searchPath = getState('application').config?.options?.paths?.search;
    const productHitsCount = totals.products + totals.spareParts;
    const searchQueryLength = searchQuery.length > 0;

    let query = searchQuery || '';

    try {
        query = decodeURI(query);
    } catch (e) {
        // console.error(e);
    }

    const sparePartPartNo = spareParts.map(item => {
        return item.manufacturerPartNo.replaceAll(/[-\s]/g, '');
    });

    const sparePartExsists = sparePartPartNo?.includes(query.replaceAll(/[-\s]/g, ''));

    const filteredSuggestions = (suggestions || []).filter(x => x.query !== query.trim());
    const isBelowXL = useBelowBreakpoint('xl');
    const sliceProducts = isBelowXL ? (5, 4) : (7, 2);

    return (
        <SearchWrapper>
            <SearchWrapperInner>
                <SearchSidebar>
                    <SearchSidebarColumn>
                        <SidebarHeading>{t('search_suggestions')}</SidebarHeading>
                        {filteredSuggestions.length > 0 ? (
                            filteredSuggestions
                                .map((item, index) => {
                                    const highlightedSearchQuery = item._highlightResult.query.value;

                                    return (
                                        <SearchCategoryButton
                                            key={index}
                                            dangerouslySetInnerHTML={{ __html: highlightedSearchQuery }}
                                            onClick={() => onSearch(item.query)}
                                        />
                                    );
                                })
                                .slice(0, 5)
                        ) : (
                            <SearchCategoryButton>{t('no_search_suggestions_found')}</SearchCategoryButton>
                        )}
                    </SearchSidebarColumn>

                    <SearchSidebarColumn>
                        <SidebarHeading>{t('categories')}</SidebarHeading>
                        {categories.length > 0 ? (
                            categories.map((item, index) => {
                                return (
                                    <SearchCategoryLink
                                        dangerouslySetInnerHTML={{ __html: item.title }}
                                        key={index}
                                        to={item.url}
                                        onClick={closeSearch}
                                    />
                                );
                            })
                        ) : (
                            <SearchCategoryButton type="button">{t('no_categories_found')}</SearchCategoryButton>
                        )}
                    </SearchSidebarColumn>

                    <SearchSidebarColumn>
                        <SidebarHeading>{t('articles.search_title')}</SidebarHeading>
                        {articles.length > 0 ? (
                            articles.map((item, index) => {
                                return (
                                    <SearchCategoryLink
                                        dangerouslySetInnerHTML={{
                                            __html: get(item, 'item._highlightResult.query.value') || item.title,
                                        }}
                                        key={index}
                                        to={item.url}
                                        onClick={closeSearch}
                                    />
                                );
                            })
                        ) : (
                            <SearchCategoryButton>{t('no_search_suggestions_found')}</SearchCategoryButton>
                        )}
                    </SearchSidebarColumn>
                </SearchSidebar>

                <Wrapper>
                    {sparePartExsists && (
                        <SpareParts>
                            <SearchContentHeader>
                                <SearchHeading>{t('spare_parts')}</SearchHeading>
                            </SearchContentHeader>
                            {spareParts &&
                                spareParts.slice(0, 1).map((item, index) => {
                                    return (
                                        <ProductCol key="index" className="spare-parts">
                                            <ProductCard
                                                isSearchItem
                                                algoliaPosition={index + 1}
                                                algoliaQueryId={queryIds.spareParts}
                                                data={item}
                                                hideItems={['addToCart', 'compare']}
                                                imageSizes="16vw"
                                                onClick={closeSearch}
                                            />
                                        </ProductCol>
                                    );
                                })}
                        </SpareParts>
                    )}

                    <Content position="relative" width="100%">
                        <SearchContentHeader>
                            <SearchHeading>{searchQueryLength ? t('products') : t('popular_products')}</SearchHeading>
                        </SearchContentHeader>
                        <ProductWrapper>
                            {searchQueryLength && allProducts.length === 0 && (
                                <Div width="100%" p="0 8px 8px">
                                    {t('search_query_not_found', { query })}
                                </Div>
                            )}
                            {allProducts &&
                                allProducts.slice(sparePartExsists ? sliceProducts : null).map((item, index) => {
                                    return (
                                        <ProductCol key={index} sparePartExsists={sparePartExsists}>
                                            <ProductCard
                                                isSearchItem
                                                algoliaPosition={index + 1}
                                                algoliaQueryId={queryIds.products}
                                                data={item}
                                                hideItems={['addToCart', 'compare']}
                                                imageSizes="16vw"
                                                onClick={closeSearch}
                                            />
                                        </ProductCol>
                                    );
                                })}
                        </ProductWrapper>

                        {searchQueryLength && productHitsCount >= 8 && (
                            <ShowMoreButton to={`${searchPath}?q=${searchQuery}`} onClick={closeSearch}>
                                {`${t('show_all')} (${productHitsCount})`}
                            </ShowMoreButton>
                        )}
                    </Content>
                </Wrapper>
            </SearchWrapperInner>
        </SearchWrapper>
    );
};

SearchContent.propTypes = {
    articles: PropTypes.array,
    categories: PropTypes.array,
    closeSearch: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    products: PropTypes.array,
    queryIds: PropTypes.shape({
        articles: PropTypes.string,
        categories: PropTypes.string,
        spareParts: PropTypes.string,
        suggestions: PropTypes.string,
        products: PropTypes.string,
    }).isRequired,
    searchQuery: PropTypes.string,
    spareParts: PropTypes.array,
    suggestions: PropTypes.array,
    totals: PropTypes.shape({
        categories: PropTypes.number,
        spareParts: PropTypes.number,
        suggestions: PropTypes.number,
        products: PropTypes.number,
    }).isRequired,
};

SearchContent.defaultProps = {
    articles: [],
    categories: [],
    products: [],
    searchQuery: '',
    spareParts: [],
    suggestions: [],
};

export default SearchContent;
