import React from 'react';
import PropTypes from 'prop-types';

import SVG from './base/SVG';

const HamburgerIcon = ({ color, ...props }) => (
    <SVG fill="none" viewBox="0 0 24 18" {...props}>
        <path stroke={color} strokeLinecap="round" strokeWidth="2" d="M1 1h22M1 9h14M1 17h22" />
    </SVG>
);

HamburgerIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

HamburgerIcon.defaultProps = {
    color: 'currentColor',
    height: '18px',
    width: '24px',
};

export default HamburgerIcon;
