import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getState } from 'core-web/state';
import get from 'core-web/util/get';
import { above, below } from 'core-web/util/mediaqueries';
import Above from 'core-web/components/Breakpoints/Above';
import Below from 'core-web/components/Breakpoints/Below';
import Link from 'core-web/components/Link';
import { inServer } from 'core-web/constants';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';
import ProductListItem from 'theme/components/product/ProductListItem';

// General
const Div = styled('div')();
const SearchWrapper = styled('div')`
    position: relative;
    background-color: ${colors.lightGrey};
    overflow: auto;
`;
const SearchHeading = styled('div')`
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 16px;
`;

// Sidebar
const SearchSidebar = styled('div')`
    min-width: 228px;
    em {
        font-style: normal;
        text-decoration: underline;
    }
`;
const SearchInfoColumn = styled('div')`
    border-bottom: 1px solid ${colors.grey};
    margin-bottom: 24px;
    padding-bottom: 24px;
    ${above.md} {
        margin-bottom: 32px;
        margin-right: 16px;
    }
`;
const textStyling = as => styled(as)`
    display: block;
    background: none;
    border-bottom: 0;
    padding: 0;

    color: inherit;
    font-size: 16px;
    font-weight: inherit;
    line-height: 32px;
    text-align: left;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    em {
        font-style: normal;
        text-decoration: underline;
    }
`;
const SearchCategoryLink = textStyling(Link);
const SearchCategoryButton = textStyling('button');
SearchCategoryButton.defaultProps = {
    type: 'button',
};

// Products
const Content = styled('div')`
    display: block;
    height: auto;
    ${above.md} {
        min-width: 397px;
    }
`;
const ProductWrapper = styled('div')`
    display: flex;
    flex-wrap: wrap;
    user-select: none;
    -webkit-overflow-scrolling: touch;
`;
const SearchContentHeader = styled('div')`
    width: 100%;
    height: auto;
    display: block;
    ${below.xl} {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
`;
const ProductListItemWrapper = styled('div')`
    display: flex;
    width: 100%;
    margin-top: 8px;
    ${below.md} {
        &:nth-of-type(n + 8) {
            display: none;
        }
    }
`;
const ShowAllButton = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 131px;
    height: 56px;
    background-color: ${colors.darkGrey};
    border: none;
    border-radius: 4px;
    padding: 0 18px;
    font: 600 16px/1em 'Akkurat';
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 50px;
`;

const SearchContent = ({
    articles,
    categories,
    closeSearch,
    hitsCount,
    onSearch,
    products,
    queryIds,
    searchQuery,
    spareParts,
    suggestions,
    totals,
}) => {
    const isMobile = inServer ? false : window.innerWidth < 575;
    const categoriesHits = isMobile ? categories.slice(0, 3) : categories;

    const searchPath = getState('application').config?.options?.paths?.search;
    const productHitsCount = totals.products + totals.spareParts;
    const searchQueryLength = searchQuery.length > 0;

    let query = searchQuery || '';

    try {
        query = decodeURI(query);
    } catch (e) {
        // console.error(e);
    }

    const sparePartPartNo = spareParts.map(item => {
        return item.manufacturerPartNo.replaceAll(/[-\s]/g, '');
    });

    const sparePartExsists = sparePartPartNo?.includes(query.replaceAll(/[-\s]/g, ''));

    const { t } = useTranslation();

    const noResults = hitsCount.all === 0;

    const filteredSuggestions = (suggestions || []).filter(x => x.query !== query.trim());

    return (
        <SearchWrapper display={['block', 'flex']} p={['24px 16px 12px 16px', '22px 16px']} width="100%" height="100%">
            <SearchSidebar flex={[null, '0 1 50%']} order="1">
                {filteredSuggestions.length > 0 ? (
                    <SearchInfoColumn flex="0 0 50%">
                        <SearchHeading>{t('search_suggestions')}</SearchHeading>
                        {filteredSuggestions
                            .map((item, index) => {
                                const highlightedSearchQuery = item._highlightResult.query.value;

                                return (
                                    <SearchCategoryButton
                                        key={index}
                                        dangerouslySetInnerHTML={{ __html: highlightedSearchQuery }}
                                        onClick={() => onSearch(item.query)}
                                    />
                                );
                            })
                            .slice(0, isMobile ? 3 : 5)}
                    </SearchInfoColumn>
                ) : (
                    <SearchInfoColumn flex="0 0 50%">
                        <SearchHeading>{t('search_suggestions')}</SearchHeading>
                        <SearchCategoryButton>{t('no_search_suggestions_found')}</SearchCategoryButton>
                    </SearchInfoColumn>
                )}

                {categoriesHits.length > 0 && (
                    <SearchInfoColumn flex="0 0 50%">
                        <SearchHeading>{t('categories')}</SearchHeading>
                        {categoriesHits.map((item, index) => (
                            <SearchCategoryLink
                                dangerouslySetInnerHTML={{ __html: item.title }}
                                key={index}
                                to={item.url}
                                onClick={closeSearch}
                            />
                        ))}
                    </SearchInfoColumn>
                )}
                <Above
                    breakpoint="md"
                    render={() => (
                        <SearchInfoColumn flex="0 0 50%">
                            <SearchHeading>{t('articles.search_title')}</SearchHeading>
                            {articles.length > 0 &&
                                articles.map((item, index) => {
                                    return (
                                        <SearchCategoryLink
                                            dangerouslySetInnerHTML={{
                                                __html: get(item, '_highlightResult.objectID.value') || item.title,
                                            }}
                                            key={index}
                                            to={item.url}
                                            onClick={closeSearch}
                                        />
                                    );
                                })}
                        </SearchInfoColumn>
                    )}
                />

                {noResults && (
                    <SearchInfoColumn flex="0 0 50%">
                        <SearchHeading>{t('categories')}</SearchHeading>
                        <SearchCategoryButton>{t('no_categories_found')}</SearchCategoryButton>
                    </SearchInfoColumn>
                )}
            </SearchSidebar>
            <Content position="relative" width="100%" flex={[null, '1 0 50%']} order="3">
                {sparePartExsists && spareParts.length > 0 && (
                    <ProductWrapper flexWrap={['nowrap', 'wrap']} pb="32px">
                        <SearchContentHeader>
                            <SearchHeading>{t('spare_parts')}</SearchHeading>
                        </SearchContentHeader>
                        {spareParts.slice(0, 1).map((product, index) => {
                            return (
                                <ProductListItemWrapper key={index}>
                                    <ProductListItem
                                        withButton
                                        algoliaPosition={index + 1}
                                        algoliaQueryId={queryIds.products}
                                        product={product}
                                        p="16px"
                                        fontSize="14px"
                                        onClick={closeSearch}
                                    />
                                </ProductListItemWrapper>
                            );
                        })}
                    </ProductWrapper>
                )}

                <ProductWrapper flexWrap={['nowrap', 'wrap']} pb="32px">
                    <SearchContentHeader>
                        <SearchHeading>{searchQueryLength ? t('products') : t('popular_products')}</SearchHeading>

                        {noResults && (
                            <Div width="100%" mt="8px">
                                {t('search_query_not_found', { query })}
                            </Div>
                        )}
                    </SearchContentHeader>
                    {products &&
                        products.map((product, index) => {
                            return (
                                <ProductListItemWrapper key={index}>
                                    <ProductListItem
                                        withButton
                                        algoliaPosition={index + 1}
                                        algoliaQueryId={queryIds.products}
                                        product={product}
                                        p="16px"
                                        fontSize="14px"
                                        onClick={closeSearch}
                                    />
                                </ProductListItemWrapper>
                            );
                        })}
                </ProductWrapper>
                {searchQueryLength && productHitsCount >= 6 && (
                    <ShowAllButton
                        color={`${colors.white}`}
                        to={`${searchPath}?q=${searchQuery}`}
                        onClick={closeSearch}
                    >
                        {`${t('search_on_all')} (${productHitsCount})`}
                    </ShowAllButton>
                )}
            </Content>
            <Below
                breakpoint="md"
                render={() => (
                    <SearchInfoColumn flex="0 0 50%">
                        <SearchHeading>{t('articles.search_title')}</SearchHeading>
                        {articles.length > 0 &&
                            articles.map((item, index) => {
                                return (
                                    <SearchCategoryLink
                                        dangerouslySetInnerHTML={{
                                            __html: get(item, '_highlightResult.objectID.value') || item.title,
                                        }}
                                        key={index}
                                        to={item.url}
                                        onClick={closeSearch}
                                    />
                                );
                            })}
                    </SearchInfoColumn>
                )}
            />
        </SearchWrapper>
    );
};

SearchContent.propTypes = {
    articles: PropTypes.array,
    categories: PropTypes.array,
    closeSearch: PropTypes.func.isRequired,
    hitsCount: PropTypes.shape({
        all: PropTypes.number,
        categories: PropTypes.number,
        spareParts: PropTypes.number,
        suggestions: PropTypes.number,
        products: PropTypes.number,
    }).isRequired,
    onSearch: PropTypes.func.isRequired,
    products: PropTypes.array,
    queryIds: PropTypes.shape({
        articles: PropTypes.string,
        categories: PropTypes.string,
        spareParts: PropTypes.string,
        suggestions: PropTypes.string,
        products: PropTypes.string,
    }).isRequired,
    searchQuery: PropTypes.string,
    spareParts: PropTypes.array,
    suggestions: PropTypes.array,
    totals: PropTypes.shape({
        categories: PropTypes.number,
        spareParts: PropTypes.number,
        suggestions: PropTypes.number,
        products: PropTypes.number,
    }).isRequired,
};

SearchContent.defaultProps = {
    articles: [],
    categories: null,
    products: null,
    searchQuery: '',
    spareParts: null,
    suggestions: null,
};

export default SearchContent;
