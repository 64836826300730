import React, { forwardRef, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

const withHeightAuto = Component =>
    forwardRef(({ height, trigger, ...rest }, ref) => {
        const node = ref || useRef();
        const lastHeight = useRef(null);
        const heightRef = useRef(null);

        const setHeight = useCallback(
            value => {
                requestAnimationFrame(() => {
                    if (node.current) {
                        node.current.style.height = value;
                        lastHeight.current = value;
                    }
                });
            },
            [node]
        );

        const debouncedQuery = debounce(() => {
            setHeight(heightRef.current);
        }, 250);

        useEffect(() => {
            heightRef.current = height;
        }, [height]);

        useEffect(() => {
            window.addEventListener('resize', debouncedQuery, false);
            return () => window.removeEventListener('resize', debouncedQuery, false);
        }, []);

        useEffect(() => {
            debouncedQuery();
        }, [trigger]);

        useLayoutEffect(() => {
            if (height === 'auto') {
                const scrollHeight = node.current.scrollHeight;

                // @todo: For some reason we can't memoize this.
                // Added parseInt here so it can be compared to a number
                if (parseInt(lastHeight.current, 10) !== scrollHeight) {
                    setHeight(`${scrollHeight}px`);
                }
            } else if (lastHeight.current !== height) {
                setHeight(height);
            }
        });

        return (
            <Component
                {...rest}
                ref={node}
                height={lastHeight.current === null && (height === '0' || height === '0px') && '0px'}
            />
        );
    });

export default withHeightAuto;
