import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Button, { Theme } from 'core-web/components/Button';
import colors from 'theme/config/colors';

const propTypes = {
    borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    font: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    isOutlined: PropTypes.bool,
    letterSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    padding: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    textTransform: PropTypes.string,
    theme: PropTypes.oneOf(['cyan', 'black', 'green']).isRequired,
};

type Props = InferProps<typeof propTypes>;

interface Themes {
    contained: {
        [key: string]: Theme;
    };
    outlined: {
        [key: string]: Theme;
    };
}

const THEMES: Themes = {
    contained: {
        green: {
            color: colors.white,
            backgroundColor: colors.green,
            borderColor: colors.green,
            hover: {
                backgroundColor: colors.lightGreen,
                borderColor: colors.lightGreen,
                duration: '300ms',
                timingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
            },
            disabled: {
                color: colors.textGrey,
                backgroundColor: colors.grey,
                borderColor: colors.grey,
            },
        },
        cyan: {
            color: colors.white,
            backgroundColor: colors.cyan,
            borderColor: colors.cyan,
            hover: {
                backgroundColor: colors.lightCyan,
                borderColor: colors.lightCyan,
                duration: '300ms',
                timingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
            },
            disabled: {
                color: colors.textGrey,
                backgroundColor: colors.grey,
                borderColor: colors.grey,
            },
        },
        black: {
            color: colors.white,
            backgroundColor: colors.darkGrey,
            hover: {
                backgroundColor: colors.darkGreyTint,
                borderColor: colors.darkGreyTint,
                duration: '300ms',
                timingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
            },
            disabled: {
                color: colors.textGrey,
                backgroundColor: colors.grey,
            },
        },
        easyPeasyGreen: {
            color: colors.white,
            backgroundColor: colors.easyPeasyGreen,
            borderColor: colors.easyPeasyGreen,
            hover: {
                opacity: '0.8',
                duration: '300ms',
                timingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
            },
            disabled: {
                color: colors.white,
                backgroundColor: colors.darkGrey,
                borderColor: colors.darkGrey,
            },
        },
    },
    outlined: {
        cyan: {
            color: colors.cyan,
            backgroundColor: 'transparent',
            borderColor: colors.cyan,
        },
        green: {
            color: colors.green,
            backgroundColor: 'transparent',
            borderColor: colors.green,
        },
        black: {
            color: colors.black,
            backgroundColor: 'transparent',
            borderColor: colors.black,
        },
    },
};

const SquareButton: React.FC<Props> = ({ theme, isOutlined, ...rest }) => {
    const btnTheme = THEMES[isOutlined ? 'outlined' : 'contained'][theme] || {};

    return <Button theme={btnTheme} {...rest} />;
};

SquareButton.propTypes = propTypes;

SquareButton.defaultProps = {
    borderRadius: '4px',
    font: '700 16px/1em Akkurat',
    isOutlined: false,
    lineHeight: '1em',
    padding: ['0 12px', null, null, '0 16px'],
    theme: 'green',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    height: '56px',
};

export default SquareButton;
