import React from 'react';
import PropTypes from 'prop-types';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import Link from 'core-web/components/Link';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const Div = styled('div')();
const MenuFooterWrapper = styled('div')`
    padding: 12px 0 70px;
    width: 100%;
`;
const CustomLink = styled(Link)`
    display: flex;
    align-items: center;
    color: inherit;
    border: none;
    padding: 16px 16px;
    font-weight: 400;
    svg {
        margin-right: 12px;
    }
`;

const MenuFooter = ({ closeMenu, contactPageUrl, contactPageText, returnPageUrl, returnPageText }) => {
    if (!(contactPageUrl && contactPageText) && !(returnPageUrl && returnPageText)) {
        return null;
    }

    return (
        <MenuFooterWrapper>
            {contactPageUrl && contactPageText && (
                <CustomLink to={contactPageUrl} onClick={closeMenu}>
                    <Div fontSize="16px" lineHeight="14px" color={colors.black}>
                        {decodeHTMLEntities(contactPageText)}
                    </Div>
                </CustomLink>
            )}
            {returnPageUrl && returnPageText && (
                <CustomLink to={returnPageUrl} onClick={closeMenu}>
                    <Div fontSize="16px" lineHeight="14px" color={colors.black}>
                        {decodeHTMLEntities(returnPageText)}
                    </Div>
                </CustomLink>
            )}
        </MenuFooterWrapper>
    );
};

MenuFooter.propTypes = {
    closeMenu: PropTypes.func.isRequired,
    contactPageText: PropTypes.string,
    contactPageUrl: PropTypes.string,
    returnPageText: PropTypes.string,
    returnPageUrl: PropTypes.string,
};

MenuFooter.defaultProps = {
    contactPageText: '',
    contactPageUrl: '',
    returnPageText: '',
    returnPageUrl: '',
};

export default MenuFooter;
