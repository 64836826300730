import { useEffect, useState } from 'react';
import { inServer } from 'core-web/constants';

const getScrollbarWidth = () => (inServer ? 0 : window.innerWidth - document.documentElement.clientWidth);

const useScrollbarWidth = () => {
    const [scrollbar, setScrollbar] = useState(getScrollbarWidth());
    // this needed to ensure the scrollbar width in case hook called before the DOM is ready
    useEffect(() => {
        if (!scrollbar) {
            setScrollbar(getScrollbarWidth());
        }
    }, [scrollbar]);

    return { scrollbarWidth: scrollbar };
};

export default useScrollbarWidth;
