import React from 'react';
import { injectModel } from 'core-web/state';
import PropTypes from 'prop-types';
import { above } from 'core-web/util/mediaqueries';
import ChevronIcon from 'theme/components/icons/ChevronIcon';
import countryIcons from 'theme/components/icons/countries';
import styled from 'theme/libs/styled';

const Selected = styled('div')`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    span {
        font-size: 16px;
        font-weight: 700;
        line-height: 1em;
        margin-left: 8px;
        margin-right: 8px;
        ${above.lg} {
            font-size: 14px;
            text-decoration: underline;
        }
    }
`;

const SelectedCountry = ({ application, isOpen, toggleCountrySelector, ...rest }) => {
    const languages = application.languages;
    const selectedCountry = languages.find(i => i.locale === application.locale) || 'sv_SE';
    const selectedName = selectedCountry.name;
    const CountryFlagIcon = countryIcons[selectedCountry.locale];

    return (
        selectedName && (
            <Selected onClick={toggleCountrySelector} {...rest}>
                {CountryFlagIcon && (
                    <CountryFlagIcon
                        className="country-icon"
                        height={['24px', null, '20px']}
                        width={['24px', null, '20px']}
                    />
                )}
                <span>{selectedName}</span>
                <ChevronIcon width="16px" direction={isOpen ? 'up' : 'down'} strokeWidth="1" />
            </Selected>
        )
    );
};

SelectedCountry.propTypes = {
    application: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggleCountrySelector: PropTypes.func.isRequired,
};

export default injectModel('application')(SelectedCountry);
