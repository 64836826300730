import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import Image from 'core-web/components/Image';
import Link from 'core-web/components/Link';
import colors from 'theme/config/colors';
import { PLACEHOLDER } from 'theme/config/constants';
import styled from 'theme/libs/styled';

const SHOW_ALL_THRESHOLD = 3;

const Div = styled('div')();

const ImageCol = styled('div')`
    margin-right: 12px;
    width: 48px;
    min-width: 48px;
    height: 48px;
    border: 1px solid ${colors.grey};
    overflow: hidden;
    border-radius: 50%;
    & > img {
        object-fit: contain;
    }
`;

const ParentLink = styled(Link)`
    display: block;
    color: ${colors.darkGrey};
    font-weight: bold;
    text-decoration: none;
    font-size: 14px;

    &:focus,
    &:hover {
        outline: none;
        text-decoration: underline;
    }
`;

const SubItemsList = styled('div')`
    &:not(.show-all) > a:nth-of-type(n + ${SHOW_ALL_THRESHOLD + 1}) {
        display: none;
    }
`;

const SubLink = styled(Link)`
    display: block;
    color: inherit;
    text-decoration: none;
    margin-bottom: 8px;
    font-size: 14px;
    &.hide {
        display: none;
    }
    &:focus,
    &:hover {
        outline: none;
        text-decoration: underline;
    }
`;

const ShowMore = styled('button')`
    display: block;
    background: none;
    border: none;
    margin: 16px 0 40px;

    color: ${colors.green};
    cursor: pointer;
    text-decoration: underline;
`;

const SubItems = ({ items, closeMenu }) => {
    const [showAll, setShowAll] = useState(false);
    const { t } = useTranslation();

    return (
        <SubItemsList className={showAll ? 'show-all' : ''}>
            {items.map((subItem, i) => {
                const menuLink = subItem.menu_link;
                const linkText = menuLink && menuLink.text;
                const linkUrl = menuLink && menuLink.url;
                return (
                    <SubLink to={linkUrl} key={i} onClick={closeMenu}>
                        {decodeHTMLEntities(linkText)}
                    </SubLink>
                );
            })}
            {items.length > SHOW_ALL_THRESHOLD && (
                <ShowMore onClick={() => setShowAll(!showAll)}>{t(showAll ? 'show_less' : 'show_more')}</ShowMore>
            )}
        </SubItemsList>
    );
};

SubItems.propTypes = {
    closeMenu: PropTypes.func,
    items: PropTypes.array.isRequired,
};

SubItems.defaultProps = {
    closeMenu: null,
};

const DropDownMenuItem = ({ item, closeMenu, parentTabIndex }) => {
    const { thumbnail, sub_links: subLinks = [], menu_link: link = {} } = item;
    const placeholder = PLACEHOLDER;

    return (
        <Div display="flex" mt="32px" alignItems={subLinks ? 'unset' : 'center'}>
            <ImageCol>
                <Link to={link.url} onClick={closeMenu}>
                    <Image
                        key={thumbnail || placeholder + parentTabIndex}
                        src={{
                            url: thumbnail || placeholder,
                            width: 80,
                            height: 80,
                        }}
                        alt={link.text}
                        placeholder={placeholder}
                        format="jpg"
                        width="100%"
                        height="100%"
                        objectFit="contain"
                    />
                </Link>
            </ImageCol>
            <Div>
                <ParentLink mb={subLinks ? '12px' : '0'} to={link.url} tabIndex={parentTabIndex} onClick={closeMenu}>
                    {decodeHTMLEntities(link.text)}
                </ParentLink>
                {subLinks && <SubItems key={link.text} items={subLinks} closeMenu={closeMenu} />}
            </Div>
        </Div>
    );
};

DropDownMenuItem.propTypes = {
    closeMenu: PropTypes.func,
    item: PropTypes.object.isRequired,
    parentTabIndex: PropTypes.number,
};

DropDownMenuItem.defaultProps = {
    closeMenu: null,
    parentTabIndex: null,
};

export default DropDownMenuItem;
