import React from 'react';
import PropTypes from 'prop-types';

import SVG from './base/SVG';

const CheckIcon = ({ color, ...props }) => (
    <SVG fill="none" viewBox="0 0 16 14" {...props}>
        <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 6.5l5.5 6L15 1" />
    </SVG>
);

CheckIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

CheckIcon.defaultProps = {
    color: 'currentColor',
    height: '14px',
    width: '16px',
};

export default CheckIcon;
