import React from 'react';
import PropTypes from 'prop-types';

import SVG from './base/SVG';

const CrossIcon = ({ color, ...props }) => (
    <SVG fill="none" viewBox="0 0 16 16" {...props}>
        <path
            fill={color}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.9.9C1.3.5 2 .5 2.5.9l12.4 12.4c.4.5.4 1.2 0 1.6-.4.4-1.1.4-1.6 0L.9 2.5C.5 2 .5 1.3 1 .9z"
        />
        <path
            fill={color}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.9 14.9c-.4-.4-.4-1.1 0-1.6L13.3.9c.5-.4 1.2-.4 1.6 0 .4.4.4 1.1 0 1.6L2.5 14.9c-.5.4-1.2.4-1.6 0z"
        />
    </SVG>
);

CrossIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

CrossIcon.defaultProps = {
    color: 'currentColor',
    height: '16px',
    width: '16px',
};

export default CrossIcon;
