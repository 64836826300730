import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SquareButton from 'theme/components/buttons/SquareButton';
import BasketIcon from 'theme/components/icons/BasketIcon';

const BasketButton = ({ basketTotal, onClick }) => {
    const { t } = useTranslation();
    return (
        <SquareButton
            height="46px"
            fontSize="14px"
            textTransform="none"
            theme="green"
            marginLeft="12px"
            onClick={onClick}
        >
            <BasketIcon width="24px" height="20px" mr="8px" />
            {t('basket')} ({basketTotal})
        </SquareButton>
    );
};

BasketButton.propTypes = {
    basketTotal: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default BasketButton;
