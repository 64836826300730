import React from 'react';
import PropTypes from 'prop-types';

import SVG from './base/SVG';

const ProfileIcon = ({ color, ...props }) => (
    <SVG fill="none" viewBox="0 0 22 22" {...props}>
        <path
            fill={color}
            d="M18.142 13.75a10.079 10.079 0 00-3.655-2.322 5.813 5.813 0 002.395-4.695C16.882 3.516 14.243.9 11.001.9 7.759.9 5.12 3.516 5.12 6.733c0 1.923.943 3.632 2.394 4.695A10.078 10.078 0 003.86 13.75l.07.071-.07-.07a9.907 9.907 0 00-2.927 6.29c-.039.495.365.892.85.892.49 0 .873-.399.918-.876.395-4.195 3.967-7.49 8.3-7.49 4.335 0 7.906 3.295 8.302 7.49.045.477.428.876.918.876.485 0 .889-.397.85-.893a9.908 9.908 0 00-2.928-6.29zm-7.141-2.933c-2.272 0-4.119-1.833-4.119-4.084 0-2.25 1.847-4.084 4.119-4.084s4.119 1.833 4.119 4.084c0 2.25-1.847 4.084-4.12 4.084z"
        />
    </SVG>
);

ProfileIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

ProfileIcon.defaultProps = {
    color: 'currentColor',
    height: '22px',
    width: '22px',
};

export default ProfileIcon;
