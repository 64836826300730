import React from 'react';
import PropTypes from 'prop-types';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import Image from 'core-web/components/Image';
import Link from 'core-web/components/Link';
import colors from 'theme/config/colors';
import { PLACEHOLDER } from 'theme/config/constants';
import styled from 'theme/libs/styled';
import ChevronIcon from 'theme/components/icons/ChevronIcon';

const Div = styled('div')();
const MenuItemsWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    height: 100%;
`;
const MenuItem = styled('div')`
    height: 64px;
    & > * {
        display: block;
        color: inherit;
        width: 100%;
        border: none;
        cursor: pointer;
        border-bottom: 1px solid ${colors.grey};
        height: 100%;
        &:nth-of-type(n + 2) {
            div {
                border-top: none;
            }
        }
    }
`;

const ImageCol = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    width: 48px;
    min-width: 48px;
    height: 48px;
    border: 1px solid ${colors.grey};
    overflow: hidden;
    border-radius: 50%;
    & > img {
        object-fit: contain;
    }
`;

const MenuItems = ({ itemClick, isShown, id, item, closeMenu }) => {
    const menuLink = item.menu_link;
    const subLink = item.sub_links;
    const menuItemText = menuLink ? menuLink.text : subLink.text;
    const thumbnail = item.thumbnail;

    const MenuButton = menuLink && menuLink.url ? Link : ({ children, ...rest }) => <span {...rest}>{children}</span>;
    const subLinkClick = (id, item, event) => {
        event.preventDefault();
        itemClick(id, item);
    };

    const placeholder = PLACEHOLDER;

    return (
        <MenuItem>
            <MenuButton
                to={menuLink && menuLink.url}
                onClick={event => (subLink ? subLinkClick(id, item, event) : closeMenu())}
            >
                <MenuItemsWrapper>
                    {isShown && (
                        <ImageCol>
                            <Image
                                key={thumbnail + id}
                                src={{
                                    url: thumbnail || placeholder,
                                    width: 80,
                                    height: 80,
                                }}
                                alt={menuItemText}
                                placeholder={placeholder}
                                format="jpg"
                                width="40px"
                                height="40px"
                                objectFit="contain"
                            />
                        </ImageCol>
                    )}
                    <Div lineHeight="18px" mr="auto">
                        {menuItemText && decodeHTMLEntities(menuItemText)}
                    </Div>
                    {subLink && <ChevronIcon direction="right" />}
                </MenuItemsWrapper>
            </MenuButton>
        </MenuItem>
    );
};

MenuItems.propTypes = {
    closeMenu: PropTypes.func,
    hideButton: PropTypes.func,
    id: PropTypes.number,
    isShown: PropTypes.bool,
    item: PropTypes.object,
    itemClick: PropTypes.func,
};

MenuItems.defaultProps = {
    closeMenu: null,
    hideButton: null,
    id: null,
    isShown: false,
    item: {},
    itemClick: null,
};

export default MenuItems;
