import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class LoadingContainer extends Component {
    static propTypes = {
        error: PropTypes.func,
        loading: PropTypes.func,
        placeholder: PropTypes.func,
        promise: PropTypes.instanceOf(Promise),
        renderProps: PropTypes.object,
        response: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]),
        success: PropTypes.func.isRequired,
    };
    static defaultProps = {
        error: () => null,
        loading: () => null,
        placeholder: () => null,
        promise: null,
        renderProps: {},
        response: null,
    };
    state = {
        fetched: false,
        loading: false,
        renderer: null,
        response: null,
    };
    static getDerivedStateFromProps(props, state) {
        if (props.response !== null && JSON.stringify(props.response) !== JSON.stringify(state.response)) {
            state.response = props.response;
            state.renderer = props.success;
            state.fetched = true;
        }
        return state;
    }

    componentDidMount() {
        this.handle();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { renderProps, response } = this.props;
        const { renderer, loading } = this.state;
        if (JSON.stringify(renderProps) !== JSON.stringify(nextProps.renderProps)) {
            return true;
        }
        if (response !== nextProps.response) {
            return true;
        }
        if (renderer !== nextState.renderer || loading !== nextState.loading) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps) {
        const { promise } = this.props;
        if (promise !== prevProps.promise) {
            this.handle(this.props);
        }
    }

    handle(props = this.props) {
        if (props.promise instanceof Promise) {
            this.setState({
                loading: true,
            });
            props.promise
                .then(() => {
                    this.setState({
                        loading: false,
                        fetched: true,
                        renderer: props.success,
                    });
                })
                .catch(() => {
                    this.setState({
                        loading: false,
                        fetched: true,
                        renderer: props.error,
                    });
                });
        }
    }

    render() {
        // promise, error, success and response is added because of exclusion
        const { promise, error, success, placeholder, loading: Loading, renderProps, ...props } = this.props;
        const { fetched, response, loading, renderer } = this.state;
        if (!fetched && placeholder) {
            return placeholder();
        }
        return (
            <Fragment>
                {loading && Loading()}
                {renderer({ ...props, ...response, ...renderProps })}
            </Fragment>
        );
    }
}
export default LoadingContainer;
