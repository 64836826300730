import React from 'react';

const hocify = useHook => {
    return InputComponent => {
        return props => {
            const result = useHook();

            return <InputComponent {...result} {...props} />;
        };
    };
};

export default hocify;
