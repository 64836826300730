import React from 'react';
import PropTypes from 'prop-types';

import SVG from './base/SVG';

const BigArrowIcon = ({ color, direction, ...props }) => (
    <SVG fill="none" viewBox="0 0 35 27" {...props}>
        {direction === 'left' && (
            <path fill={color} d="M0 13.3L13.3 0l1.6 1.7L4.5 12H35v2.3H4.3L14.9 25l-1.6 1.7L0 13.3z" />
        )}
        {direction === 'right' && (
            <path fill={color} d="M35 13.3L21.7 26.7 20.1 25l10.4-10.4H0v-2.3h30.7L20.1 1.7 21.7 0 35 13.3z" />
        )}
        {direction === 'up' && (
            <path
                fill={color}
                d="M13.5.847L26.848 14.14l-1.663 1.596L14.768 5.38v30.468h-2.32V5.12L1.817 15.736.153 14.14 13.5.847z"
            />
        )}
    </SVG>
);

BigArrowIcon.propTypes = {
    color: PropTypes.string,
    direction: PropTypes.oneOf(['left', 'right', 'up']).isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

BigArrowIcon.defaultProps = {
    color: 'currentColor',
    height: '27px',
    width: '35px',
};

export default BigArrowIcon;
